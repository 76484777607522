<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-10 mx-auto">
        <div class="card border-0 shadow-none">
          <div class="card-body p-5">
            <div class="row">
              <div class="col">
                <h4 class="fw-bold mb-4">Amchara Retreats Staff Portal</h4>
                <div class="alert alert-success">
                  <p class="mb-0">Login to use the staff portal</p>
                </div>
                <div class="row">
                  <div class="col-auto ms-auto">
                    <router-link class="btn btn-success" to="/login"
                      ><i class="far fa-sign-in me-2"></i>Login</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
